<template>
  <div class="comparison-page">
    <CustomPageHeader title="Comparison" :hasSubtitle="false">
      <template #title-actions>
        <div id="level-select" class="flex-align-center">
          <a-select
            ref="level-select"
            v-model:value="selectedCategoryLevel"
            :getPopupContainer="(trigger) => trigger.parentNode"
            class="rounded-select"
            style="width: 130px"
            @change="onLevelChange"
            :disabled="!hasCategory"
            :placeholder="`No Category`"
          >
            <a-select-option v-for="(level, index) in categoryLevelNameList" :key="index">
              {{ level }}
            </a-select-option>
          </a-select>
        </div>
      </template>
    </CustomPageHeader>
    <div class="page-content">
      <div class="overview-header">
        <div class="section-title">Overview</div>
        <div v-if="!isMobileScreen" class="social-movement-filter-mode">
          <span class="social-movement-data-aspect">Measurement: </span>
          <a-radio-group v-model:value="selectedDataAspect" button-style="solid" size="default">
            <a-radio-button v-for="mode in modeOptions" :key="mode.value" :value="mode.value" style="height: 34px">
              {{ mode.label }}
            </a-radio-button>
          </a-radio-group>
        </div>
        <div v-else class="mobile-social-movement-filter-mode">
          <a-dropdown placement="bottomRight" :getPopupContainer="(trigger) => trigger.parentNode">
            <img :src="require('@/assets/images/icon/grey-setting.svg')" />
            <template #overlay>
              <a-menu id="mode-menu-mobile" @click="handleModeClick">
                <a-menu-item-group title="Measurement:">
                  <a-menu-item
                    v-for="mode in modeOptions"
                    :key="mode.value"
                    :style="selectedDataAspect === mode.value ? selectedModeStyle : unSelectedModeStyle"
                  >
                    {{ mode.label }}
                  </a-menu-item>
                </a-menu-item-group>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
      <!-- <a-row :gutter="25" class="section-wrapper">
        <a-col :xs="24" :md="12">
          <div class="section-title">Overview</div>
        </a-col>
        <a-col :xs="24" :md="12">
          <div class="social-movement-filter-mode">
            <span class="social-movement-data-aspect">Measurement: </span>
            <a-radio-group v-model:value="selectedDataAspect" button-style="solid" size="default">
              <a-radio-button value="engagement" style="height: 34px">Engagement</a-radio-button>
              <a-radio-button value="mentioned" style="height: 34px">Mentioned</a-radio-button>
              <a-radio-button value="view" style="height: 34px">View</a-radio-button>
            </a-radio-group>
          </div>
        </a-col>
      </a-row> -->
      <a-row :gutter="25">
        <a-col :xxl="24" :xl="24" :lg="24" :xs="24">
          <SocialMovement :mode="selectedDataAspect" :filterResult="filterResult" />
        </a-col>
      </a-row>
      <a-row>
        <a-col :xs="24">
          <TrendingComparison :mode="selectedDataAspect" :filterResult="filterResult" />
        </a-col>
      </a-row>
      <a-row>
        <a-col :xs="24">
          <ChannelOverview :mode="selectedDataAspect" :filterResult="filterResult" />
        </a-col>
      </a-row>
      <a-row :gutter="25" class="section-wrapper">
        <a-col :xs="24">
          <div class="section-title">Sentiment and Mentioned & Engagement</div>
        </a-col>
      </a-row>
      <a-row :gutter="25">
        <a-col :xs="24" :lg="10">
          <SentimentComparison :filterResult="filterResult" />
        </a-col>
        <a-col :xs="24" :lg="14">
          <PlatformMentionEngagement :filterResult="filterResult" />
        </a-col>
      </a-row>
      <a-row :gutter="25" class="section-wrapper">
        <a-col :xs="24">
          <div class="section-title">Key highlight</div>
        </a-col>
      </a-row>
      <a-row :gutter="25">
        <a-col :xxl="24" :xl="24" :lg="24" :xs="24">
          <KeyHighlight :mode="selectedDataAspect" :filterResult="filterResult" />
        </a-col>
      </a-row>
    </div>

    <!-- <ComparisonByModal
      :visible="comparisonModalVisible"
      :modal-selected="modalSelected"
      @filterModalChange="onModalFilterChange"
      @close="toggleComparisonByModal(false)"
    /> -->
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { useStore } from 'vuex';
import { ref, reactive, computed, watch } from 'vue';
import SocialMovement from './SocialMovement.vue';
import TrendingComparison from './TrendingComparison.vue';
import SentimentComparison from './SentimentComparison.vue';
import PlatformMentionEngagement from './PlatformMentionEngagement.vue';
import ChannelOverview from './ChannelOverview.vue';
import KeyHighlight from './KeyHighlight.vue';
import CustomPageHeader from '@/components/Layout/CustomPageHeader.vue';
// import ComparisonByModal from '@/components/Modal/ComparisonByModal.vue';
// import _ from 'lodash';

export default {
  name: 'Comparison',
  components: {
    SocialMovement,
    TrendingComparison,
    SentimentComparison,
    PlatformMentionEngagement,
    ChannelOverview,
    KeyHighlight,
    CustomPageHeader,
    // ComparisonByModal,
  },
  setup() {
    const store = useStore();
    const { state } = store;
    const categoryData = computed(() => state.config.categoryData);
    let mode = ref('engagement'); //engagement,mention,view
    let selectedDataAspect = ref('engagement');
    let selectedDataAspect2 = ref('engagement');
    let comparisonModalVisible = ref(false);
    const hasCategory = computed(()=>{
      return categoryData.value && categoryData.value.length > 0
    })

    const selectedCategoryLevel = ref(0)


    const modeOptions = [
      { label: 'Engagement', value: 'engagement' },
      { label: 'Mentioned', value: 'mentioned' },
      { label: 'View', value: 'view' },
    ];

    const filterCriteria = computed(() => store.getters['filter/filterCriteria']);
    const categoryLevelNameList = computed(() => store.getters['config/categoryLevelNameList']);
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    // const overrideCriteria = reactive({});
    const finalCriteria = computed(() => {
      return {
        ...filterCriteria.value,
      };
    });

    watch(selectedDataAspect, (previous) => {
      mode.value = previous;
    });

    watch(
      () => filterCriteria.value,
      () => {
        onFilterCriteriaChange();
      },
      { deep: true }
    );

    // For components (set default when loading for the first time)
    let filterResult = reactive({
      level: 0,
      categoryList: filterCriteria.value.category,
      valid: true,
      filterData: finalCriteria.value,
    });

    const handleModeClick = (e) => {
      selectedDataAspect.value = e.key;
    };
    // Categories selected inside the modal (set default when loading for the first time)
    // let modalSelected = reactive({
    //   level: 0,
    //   categoryList: filterCriteria.value.category,
    // });
    // const setModalSeleted = (data) => {
    //   modalSelected.level = data.level;
    //   modalSelected.categoryList = data.category;
    // };

    // let modalFilter = reactive({
    //   name: null,
    //   level: null,
    //   category: null,
    // });
    // const setModalFilter = (data) => {
    //   modalFilter.name = data.name;
    //   modalFilter.level = data.level;
    //   modalFilter.category = data.category;
    // };

    let comparisonButtonDetail = reactive({
      title: 'Brand',
      count: 0,
    });
    // const setComparisonButton = (data) => {
    //   comparisonButtonDetail.title = data.name;
    //   comparisonButtonDetail.count = data.category && data.category.length ? data.category.length : null;
    // };

    // const onModalFilterChange = (filter) => {
    //   toggleComparisonByModal(false);
    //   setModalFilter(filter);
    //   setModalSeleted(filter);
    //   setComparisonButton(filter);

    //   const { intersectResult, overrideFilter } = checkCategoryIntersect();

    //   // Set overrideCriteria -> change data on page
    //   setOverrideCriteria(overrideFilter);

    //   let filterData = {
    //     ...filterCriteria.value,
    //     ...overrideFilter,
    //     ...overrideCriteria,
    //   };

    //   // Set filter result
    //   filterResult.level = modalFilter.level;
    //   filterResult.categoryList = [...modalFilter.category];
    //   filterResult.valid = intersectResult && intersectResult.length ? true : false;
    //   filterResult.filterData = filterData;
    // };

    const onFilterCriteriaChange = () => {
      // const { intersectResult, overrideFilter } = checkCategoryIntersect();

      // Set overrideCriteria -> change data on page
      // setOverrideCriteria(overrideFilter);

      let filterData = {
        ...filterCriteria.value,
        // ...overrideFilter,
        // ...overrideCriteria,
      };

      // Set filter result
      // filterResult.level = modalFilter.level;
      // filterResult.categoryList = [...modalFilter.category];
      // filterResult.valid = intersectResult && intersectResult.length ? true : false;
      if (filterResult.level === 0) {
        filterResult.categoryList = [...filterData.category];
      } else {
        const l = filterResult.level - 1;
        // when unselect subcat
        if (filterData.subCategory && filterData.subCategory.length) {
          const k = filterData.subCategory[l].category;
          filterResult.categoryList = [...k];
        } else {
          filterResult.categoryList = [...filterData.category];
        }
      }

      filterResult.filterData = filterData;
    };

    const onLevelChange = (level) => {
      // console.log('onchnge level', level);
      filterResult.level = level;
      onFilterCriteriaChange();
    };

    // const checkCategoryIntersect = () => {
    //   let originalFilter = { ...filterCriteria.value };

    //   let intersectResult = [];
    //   let overrideFilter = {};
    //   overrideFilter.category = [...originalFilter.category];
    //   overrideFilter.subCategory = [...originalFilter.subCategory];

    //   if (Number(modalFilter.level) > 0) {
    //     let originalSubCategoryList = [...originalFilter.subCategory];
    //     for (let item of originalSubCategoryList) {
    //       if (item.level === modalFilter.level) {
    //         intersectResult = intersectCategory(modalFilter.category, item.category);
    //       }
    //     }

    //     // change in the category by level selected
    //     overrideFilter.subCategory[Number(modalFilter.level) - 1] = {
    //       level: modalFilter.level,
    //       category: intersectResult,
    //     };
    //   } else {
    //     intersectResult = intersectCategory(modalFilter.category, filterCriteria.value.category);
    //     overrideFilter.category = intersectResult;
    //   }

    //   return {
    //     intersectResult,
    //     overrideFilter,
    //   };
    // };

    // const setOverrideCriteria = (overrideFilter) => {
    //   overrideCriteria['category'] = overrideFilter.category;
    //   overrideCriteria['subCategory'] = overrideFilter.subCategory;
    // };

    // const intersectCategory = (overrideFilter, originalFilter) => {
    //   return _.intersection(overrideFilter, originalFilter);
    // };

    // const toggleComparisonByModal = (state) => {
    //   comparisonModalVisible.value = state;
    // };

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    return {
      categoryLevelNameList,
      mode,
      selectedDataAspect,
      selectedDataAspect2,
      finalCriteria,
      comparisonModalVisible,
      comparisonButtonDetail,
      // toggleComparisonByModal,
      filterResult,
      onLevelChange,
      isMobileScreen,
      handleModeClick,
      modeOptions,
      selectedModeStyle,
      unSelectedModeStyle,
      selectedCategoryLevel,
      hasCategory
      // onModalFilterChange,
      // modalFilter,
      // modalSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
.comparison-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .page-content {
    overflow: auto;
    padding: 0px 30px 20px;
    // background-color: rgb(244, 245, 247);
    @media only screen and (max-width: 1199px) {
      padding: 0px 15px;
    }
    @media only screen and (max-width: 991px) {
      min-height: 580px;
    }
    .w-100 {
      width: 100%;
    }
    .section-title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }
    .overview-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0px;
      .social-movement-filter-mode {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .social-movement-data-aspect {
          margin-right: 14px;
          font-size: 14px;
          color: #5a5f7d;
        }
      }
    }
    .section-wrapper {
      margin-top: 24px;
      margin-bottom: 24px;
      align-items: center;

      // @media screen and (max-width: 767px) {
      //   .section-title {
      //     text-align: center;
      //     margin-bottom: 12px;
      //   }
      //   .social-movement-filter-mode {
      //     justify-content: center;
      //     text-align: center;
      //   }
      // }
    }

    .category-select-list {
      margin-right: 5px;
    }
  }

  .ant-dropdown {
    .ant-dropdown-menu {
      min-width: 200px;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 0;
    }
  }
}
</style>
