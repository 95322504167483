<template>
  <a-card class="social-movement-wrapper no-body-padding" :widget="true" :title="'Social Movement'">
    <div v-if="chartLoading" class="load-wrapper">
      <a-skeleton :loading="chartLoading" active />
    </div>
    <!-- <div v-else-if="!chartLoading && chartData.length <= 0" class="no-data-wrapper">
      <NoResult></NoResult>
    </div> -->
    <div v-if="!chartLoading && level > minLevel" class="breadcrumb-menu">
      <div class="arrow-back">
        <a-button type="ghost" class="ant-btn-white" @click="back()">
          <template #icon><FeatherIcon type="arrow-left" /></template>
          <span class="back-text mr-2">Back</span>
          <div class="level-list">
            |&nbsp;&nbsp;
            <span v-if="!isMobileScreen">
              <span v-for="(c, cIndex) in overrideCategoryList" :key="cIndex" class="level-item">
                <span v-if="cIndex > 0"> / </span>
                <span :style="{ color: c.color }">{{ c.data[0] }}</span>
              </span>
            </span>
            <a-breadcrumb v-else>
              <span v-for="(c, cIndex) in overrideCategoryListData" :key="cIndex" class="level-item">
                <a-breadcrumb-item
                  v-if="!c.isChild && (cIndex === 0 || cIndex === overrideCategoryListData.length - 1)"
                >
                  <span :style="{ color: c.color }">
                    {{ c.data[0] }}
                  </span>
                </a-breadcrumb-item>
                <!-- <span :style="{ color: c.color }">{{ c.data[0] }}</span> -->
                <a-breadcrumb-item v-if="c.isChild && c.children && c.children.length">
                  <a @click.prevent>
                    ....
                    <DownOutlined />
                  </a>
                  <template #overlay>
                    <a-menu @click="back()">
                      <a-menu-item v-for="(ch, idx) in c.children" :key="idx">
                        <span :style="{ color: ch.color }"> {{ ch.name }}</span>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-breadcrumb-item>
              </span>
            </a-breadcrumb>
          </div>
        </a-button>
      </div>
    </div>
    <a-row v-if="!chartLoading && chartData.length > 0" :gutter="25" class="social-movement-card">
      <a-col :xs="24" :md="14" class="chart-column-card">
        <!-- <div v-if="!chartLoading && level > minLevel" class="space-between-center">
          <div class="arrow-back">
            <a-button type="ghost" class="ant-btn-white" @click="back()">
              <template #icon><FeatherIcon type="arrow-left" /></template>
              <span class="back-text mr-2">Back</span>
              <span class="level-list">
                |&nbsp;
                <span v-for="(c, cIndex) in overrideCategoryList" :key="cIndex">
                  <span v-if="cIndex > 0"> / </span>
                  <span :style="{ color: c.color }">{{ c.data[0] }}</span>
                </span>
              </span>
            </a-button>
          </div>
        </div> -->

        <div v-if="level > 0" class="d-flex"></div>
        <div v-if="!chartLoading && chartData.length > 0" class="chart-wrapper">
          <EChart
            ref="comparisonEchart"
            :type="'pie'"
            :data="chartData"
            :level="level"
            :doughnut-chart="true"
            chart-type="comparison"
            @chartClick="onChartClick"
          />
        </div>
      </a-col>
      <a-col v-if="!chartLoading && chartData.length > 0" :xs="24" :md="10" class="category-list-block">
        <div class="category-list-wrapper">
          <div v-for="(item, idx) in paginatedData" :key="idx" class="category-item" @click="onLegendClick(item)">
            <div class="left-block">
              <div class="badge-wrapper">
                <a-badge :color="item.isSelected ? '#cccccc' : item.itemStyle.color" />
              </div>
              <div class="category-title">
                <div class="category-name">
                  <span :class="{ 'color-muted': item.isSelected }"> {{ item.name }} </span>
                </div>
              </div>
            </div>
            <div class="right-block">
              <div class="right-value">
                <div class="value" :class="{ 'color-muted': item.isSelected }">
                  {{ convertValue(item.value) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="paginate-button">
          <a-button
            type="ghost"
            class="ant-btn-sm circular"
            style="width: 34px"
            :disabled="currentPage === 1"
            @click="backPage"
          >
            <FeatherIcon type="chevron-left" size="18" />
          </a-button>

          <a-button
            type="ghost"
            class="ant-btn-sm circular"
            style="width: 34px"
            :disabled="currentPage === totalPage"
            @click="nextPage"
          >
            <FeatherIcon type="chevron-right" size="18" />
          </a-button>
        </div>
      </a-col>
    </a-row>
    <div v-if="!chartLoading && chartData.length <= 0" class="no-data-wrapper">
      <NoResult></NoResult>
    </div>

    <div v-if="!chartLoading" class="pa-3" :class="{ 'text-right': !isMobileScreen }">
      <a @click="changeStateTable" :class="{ 'disabled': noData }"> {{ showTable ? 'Hide ' : 'Show ' }}metric detail </a>
    </div>

    <!-- Table -->
    <a-row v-show="!chartLoading && showTable" class="social-movement-card social-movement-table">
      <a-col>
        <div v-if="tableLoading" class="load-wrapper">
          <a-skeleton :loading="tableLoading" active />
        </div>
        <!-- <a-skeleton v-if="tableLoading" :loading="tableLoading" active /> -->
        <div v-if="!tableLoading && comparisonTableData.length > 0" class="table-wrapper">
          <!-- <div class="comparison-table-scroll">
            <table class="comparison-table">
              <thead>
                <tr>
                  <th scope="col" class="column-title">Metric</th>
                  <th
                    v-for="(category, idx) in comparisonCategory"
                    :key="idx"
                    scope="col"
                    class="column-title-brand"
                    :style="{ background: getColorRgb(category, idx) }"
                  >
                    <div class="badge-wrapper">
                      <a-badge :color="getColor(category, idx)" />
                      <span class="text-brand"> {{ category }} </span>
                    </div>

                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(stat, key) in comparisonStat" :key="key">
                  <td v-if="stat.isTitle" scope="row" class="divide-row-title">{{ stat.metric }}</td>
                  <th v-if="!stat.isTitle" class="row-title">
                    <FeatherIcon :type="getIcon(stat.metric)" />
                    <span class="metric-name">{{ convertMetricName(stat.metric) }}</span>
                  </th>
                  <template v-for="(category, idx) in comparisonCategory" :key="idx">
                    <td v-if="stat.isTitle" class="row-title-blank"></td>
                    <td
                      v-if="!stat.isTitle"
                      class="table-content"
                      :class="`column-${idx}`"
                      :style="{ background: getColorRgb(category, idx) }"
                    >
                      {{ convertValue(stat[category.toLowerCase()]) }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div> -->

          <div class="compairison-table">
            <a-table
              :data-source="comparisonTableData"
              :pagination="false"
              :scroll="{ x: 'calc(500px + 50%)', y: 300 }"
            >
              <a-table-column key="category" data-index="category" fixed="left" width="300">
                <template #title><span>Metric</span></template>
                <template #default="{ record, index }">
                  <div class="badge-wrapper">
                    <a-badge :color="getColor(record.category, index)" />
                    <span class="category-text"> {{ record.category }} </span>
                  </div>
                </template>
              </a-table-column>

              <a-table-column-group>
                <template #title><span>Engagement</span></template>
                <a-table-column key="reactions" data-index="reactions" width="115">
                  <template #title>
                    <FeatherIcon :type="getIcon('reactions')" />
                    <span class="metric-name">Reaction</span>
                  </template>
                  <template #default="{ record }">
                    <span class="category-text"> {{ convertValue(record.reactions) }} </span>
                  </template>
                </a-table-column>
                <a-table-column key="comment" data-index="comment" width="115">
                  <template #title>
                    <FeatherIcon :type="getIcon('comment')" />
                    <span class="metric-name">Comment</span>
                  </template>
                  <template #default="{ record }">
                    <span class="category-text"> {{ convertValue(record.comment) }} </span>
                  </template>
                </a-table-column>
                <a-table-column key="share" data-index="share" width="115">
                  <template #title>
                    <FeatherIcon :type="getIcon('share')" />
                    <span class="metric-name">Share</span>
                  </template>
                  <template #default="{ record }">
                    <span class="category-text"> {{ convertValue(record.share) }} </span>
                  </template>
                </a-table-column>
                <a-table-column key="engagementPerMention" data-index="engagementPerMention" width="115">
                  <template #title>
                    <span class="metric-name">Engagement<br />/Mentioned</span>
                  </template>
                  <template #default="{ record }">
                    <span class="category-text"> {{ convertValue(record.engagementPerMention) }} </span>
                  </template>
                </a-table-column>
              </a-table-column-group>

              <a-table-column-group>
                <template #title><span>Content</span></template>
                <a-table-column key="textCount" data-index="textCount">
                  <template #title>
                    <FeatherIcon :type="getIcon('textCount')" />
                    <span class="metric-name">Text</span>
                  </template>
                  <template #default="{ record }">
                    <span class="category-text"> {{ convertValue(record.textCount) }} </span>
                  </template>
                </a-table-column>
                <a-table-column key="photoCount" data-index="photoCount">
                  <template #title>
                    <FeatherIcon :type="getIcon('photoCount')" />
                    <span class="metric-name">Photo</span>
                  </template>
                  <template #default="{ record }">
                    <span class="category-text"> {{ convertValue(record.photoCount) }} </span>
                  </template>
                </a-table-column>
                <a-table-column key="videoCount" data-index="videoCount">
                  <template #title>
                    <FeatherIcon :type="getIcon('videoCount')" />
                    <span class="metric-name">Video</span>
                  </template>
                  <template #default="{ record }">
                    <span class="category-text"> {{ convertValue(record.videoCount) }} </span>
                  </template>
                </a-table-column>
              </a-table-column-group>
            </a-table>
          </div>
        </div>
        <div v-if="!tableLoading && comparisonTableData.length <= 0" class="load-wrapper">
          <NoResult></NoResult>
        </div>
      </a-col>
    </a-row>
    <!-- Table -->
  </a-card>
</template>

<script>
import { useStore } from 'vuex';
import { ref, reactive, computed, toRefs, watch, onMounted } from 'vue';
// import VueTypes from 'vue-types';
import api from '@/services/api';
import helper from '@/services/helper';
import EChart from '@/components/Chart/Echart.vue';
import NoResult from '@/components/Error/NoResult.vue';
import NoDataAspect from '@/components/Error/NoDataAspect.vue';
// import numeral from 'numeral';
// import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
// import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

const SocialMovement = {
  name: 'SocialMovement',
  components: {
    EChart,
    NoResult,
    NoDataAspect,
  },
  props: {
    mode: {
      type: String,
      default: 'engagement',
    },
    filterResult: Object,
  },
  setup(props) {
    const store = useStore();
    let { mode, filterResult } = toRefs(props);
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const categoryNameMap = computed(() => store.getters['config/defaultCategoryVisibleMap']);
    const categoryNameList = computed(() => store.getters['config/categoryNameList']);
    const categoryLevelNameList = computed(() => store.getters['config/categoryLevelNameList']);
    const maxLevel = computed(() => store.getters['config/totalCategory']);
    const minLevel = ref(0);
    let level = ref(0);
    let chartLoading = ref(true);
    let tableLoading = ref(true);
    const showTable = ref(false);

    const overrideCategoryList = ref([]);

    const init = () => {
      // const { level } = filterResult.value;
      currentPage.value = 1;
      overrideCategoryList.value = [];
      level.value = Number(filterResult.value.level);
      minLevel.value = Number(filterResult.value.level);
      initTableData();
      initChartData();
    };

    onMounted(() => init());

    // watch(filterResult.value, init, { deep: true });
    watch(() => filterResult.value.filterData, init);

    // Table
    // let comparisonData = reactive({});
    let comparisonData = ref([]);
    // const metricColumnList = reactive([
    //   'reactions',
    //   'comment',
    //   'share',
    //   'engagementpermention',
    //   'textcount',
    //   'photocount',
    //   'videocount',
    // ]);

    const initTableData = () => {
      const { level, filterData } = filterResult.value;
      // loadTableData(filterData, level);
      loadComparisonTable(filterData, level);
    };

    const loadComparisonTable = async (apiFilter, level) => {
      tableLoading.value = true;

      if (filterResult.value.valid) {
        const result = await api.getCategoryCompareStat(apiFilter, level).catch(() => {
          // console.error('stat', result);
        });
        comparisonData.value = result.message.stat;
      }

      tableLoading.value = false;
    };

    const comparisonTableData = computed(() => {
      const arr = [];
      if (comparisonData && comparisonData.value.length) {
        for (let item of comparisonData.value) {
          arr.push(item);
        }

        // Sort by metricColumnList
        // arr.sort(
        // (a, b) => metricColumnList.indexOf(a.metric.toLowerCase()) - metricColumnList.indexOf(b.metric.toLowerCase())
        // );

        // // Row 1
        // arr.splice(0, 0, {
        //   isTitle: true,
        //   metric: 'Engagement',
        // });
        // // Row last-3
        // arr.splice(-3, 0, {
        //   isTitle: true,
        //   metric: 'Content',
        // });
      }
      return arr.sort((a, b) => {
        if (a.category === 'others') {
          return 1;
        } else if (b.category === 'others') {
          return -1;
        } else {
          return 0;
        }
      });
    });

    const noData = computed(
      () => (!chartLoading.value && chartData.value.length <= 0)
    );

    // const loadTableData = async (apiFilter, level) => {
    //   tableLoading.value = true;
    //   let finalResult = [];
    //   // const { categoryList } = filterResult.value;
    //   // let c = categoryList;
    //   // if (c.length === 0) {
    //   //   c = categoryNameMap.value[level];
    //   // }
    //   let c = categoryNameMap.value[level];
    //   // console.log('CCC', c);

    //   if (filterResult.value.valid) {
    //     const result = await api.getCategoryPivotStat(apiFilter, level).catch(() => {
    //       // console.error('stat pivot', result);
    //     });

    //     // console.log('RESULT', result.message.stat);
    //     if (result.message && result.message.stat && result.message.stat.length) {
    //       for (let stat of result.message.stat) {
    //         const statObj = stat;

    //         let objResult = {};
    //         objResult['metric'] = statObj.metric;

    //         for (let catKey of c) {
    //           if (typeof statObj[catKey] === 'number') {
    //             objResult[catKey] = statObj[catKey] ? statObj[catKey] : 0;
    //           }
    //         }
    //         finalResult.push(objResult);
    //       }
    //     }

    //     // if (result && result.message !== null) {
    //     //   Object.assign(comparisonData, result.message);
    //     // }
    //     comparisonData.value = finalResult;
    //   }

    //   tableLoading.value = false;
    // };

    // const comparisonCategory = computed(() => {
    //   const arr = [];
    //   if (chartData.value && chartData.value.length) {
    //     // sort by chartData
    //     const keys = chartData.value
    //       .sort((a, b) => {
    //         return b.value - a.value;
    //       })
    //       .map((obj) => obj.name);
    //     // const keys = Object.keys(comparisonData.value[0]).filter((obj) => obj !== 'metric');
    //     return keys;
    //   } else if (comparisonData.value && comparisonData.value.length) {
    //     // no chartData > sort by comparisonData > show 0 in table
    //     const keys = Object.keys(comparisonData.value[0])
    //       .filter((obj) => obj !== 'metric')
    //       .sort((a, b) => {
    //         return comparisonData.value[0][b] - comparisonData.value[0][a];
    //       });
    //     return keys;
    //   }
    //   return arr;
    // });

    // const comparisonStat = computed(() => {
    //   const arr = [];
    //   if (comparisonData && comparisonData.value.length) {
    //     for (let item of comparisonData.value) {
    //       arr.push(item);
    //     }

    //     // Sort by metricColumnList
    //     arr.sort(
    //       (a, b) => metricColumnList.indexOf(a.metric.toLowerCase()) - metricColumnList.indexOf(b.metric.toLowerCase())
    //     );

    //     // Row 1
    //     arr.splice(0, 0, {
    //       isTitle: true,
    //       metric: 'Engagement',
    //     });
    //     // Row last-3
    //     arr.splice(-3, 0, {
    //       isTitle: true,
    //       metric: 'Content',
    //     });
    //   }
    //   return arr;
    // });

    const getIcon = (metric) => {
      const icon = {
        comment: {
          value: 'message-square',
        },
        engagementPerMention: {
          value: 'at-sign',
        },
        photoCount: {
          value: 'image',
        },
        reactions: {
          value: 'thumbs-up',
        },
        share: {
          value: 'share-2',
        },
        textCount: {
          value: 'type',
        },
        videoCount: {
          value: 'video',
        },
      };
      if (icon[metric]) {
        return icon[metric].value;
      }
      return '';
    };
    const convertMetricName = (name) => {
      const metricName = {
        comment: {
          value: 'Comment',
        },
        engagementPerMention: {
          value: 'Engagement/Mentioned',
        },
        photoCount: {
          value: 'Photo',
        },
        reactions: {
          value: 'Reactions',
        },
        share: {
          value: 'Share',
        },
        textCount: {
          value: 'Text',
        },
        videoCount: {
          value: 'Video',
        },
      };

      if (metricName[name]) {
        return metricName[name].value;
      }
      return name;
    };

    const getColorRgb = (catKey, index) => {
      const color = getColor(catKey, index);
      const rgbColor = helper.hexToRGB(color, '0.1');
      return rgbColor;
    };

    const getColor = (catKey, index) => {
      const level = qs.level;
      return helper.getColorByCategory(level, catKey, index);
    };

    // Chart
    let engagementData = ref([]);
    let countData = ref([]);
    let viewData = ref([]);
    const comparisonEchart = ref(null);
    // let noData = ref(false);

    const qs = reactive({
      'chart-type': 'pie',
      level: 0,
    });
    const initChartData = () => {
      // qs['level'] = level.value;
      // await getCategory(filter.value, qs);
      const { level, filterData } = filterResult.value;
      qs['level'] = Number(level);
      // await getCategory(filterData, qs);
      getCategory(filterData, qs);
    };

    const prepareChartData = (data, c, valid) => {
      let total = 0;
      let visibleData = [];
      if (data) {
        for (const [key, value] of Object.entries(data)) {
          total = total + value;
          visibleData.push({
            name: key,
            value: value,
          });
        }
      }
      let finalResult = [];
      if (valid) {
        if (data !== null) {
          if (data) {
            if (c) {
              for (const catKey of c) {
                const color = getColor(catKey, finalResult.length);
                finalResult.push({
                  name: helper.capitalize(catKey),
                  value: data[catKey] ? data[catKey] : 0,
                  isSelected: false,
                  itemStyle: {
                    color: color,
                  },
                });
              }
            }
            let noValue = finalResult.every((ele) => ele.value === 0); // show no data
            if (noValue) {
              return [];
            }
          }
        }
        return finalResult.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
      } else {
        for (const catKey of c) {
          const color = getColor(catKey, finalResult.length);
          // const color = helper.getColor(catKey, finalResult.length);
          finalResult.push({
            name: catKey,
            value: 0,
            isSelected: false,
            itemStyle: {
              color: color,
            },
          });
        }
      }
      return finalResult;
    };

    const getCategory = async (apiFilter, qs) => {
      chartLoading.value = true;
      // let finalResult = [];
      const { valid } = filterResult.value;
      // console.log('category', categoryList, qs.level);

      // let c = [];
      // if (c.length === 0) {
      //   c = categoryNameMap.value[qs.level];
      // }
      let c = categoryNameMap.value[qs.level];
      // console.log('C', c);

      let result = await api.getCategoryPie(apiFilter, qs).catch(() => {});
      if (result && result.message != null && result.message.graphData != null) {
        const { engagement, view, count } = result.message.graphData;
        // noData.value = false;
        engagementData.value = prepareChartData(engagement, c, valid);
        countData.value = prepareChartData(count, c, valid);
        viewData.value = prepareChartData(view, c, valid);
      }

      chartLoading.value = false;
    };

    const chartData = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          // return engagementData.value;
          if (engagementData.value) {
            engagementData.value.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
            return engagementData.value.sort((a, b) => {
              if (a.name === 'Others') {
                return 1;
              } else if (b.name === 'Others') {
                return -1;
              } else {
                return 0;
              }
            });
          }
        } else if (mode.value.toLowerCase() === 'mentioned') {
          // return countData.value;
          if (countData.value) {
            countData.value.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
            return countData.value.sort((a, b) => {
              if (a.name === 'Others') {
                return 1;
              } else if (b.name === 'Others') {
                return -1;
              } else {
                return 0;
              }
            });
          }
        } else if (mode.value.toLowerCase() === 'view') {
          // return viewData.value;
          if (viewData.value) {
            viewData.value.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
            return viewData.value.sort((a, b) => {
              if (a.name === 'Others') {
                return 1;
              } else if (b.name === 'Others') {
                return -1;
              } else {
                return 0;
              }
            });
          }
        }
      }
      // return engagementData.value;
      if (engagementData.value) {
        return engagementData.value.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
      }
      return [];
    });

    const onLegendClick = (item) => {
      if (item.isSelected) {
        item.isSelected = false;
      } else {
        item.isSelected = true;
      }
      comparisonEchart.value.eChart.dispatchAction({
        type: 'legendToggleSelect',
        // legend name
        name: item.name,
      });
    };

    const prepareOverrideCategoryData = () => {
      const { filterData } = filterResult.value;
      let category = [];
      const subCategory = [];
      if (overrideCategoryList.value[0]) {
        category = overrideCategoryList.value[0].data;
      }
      // if (filterData.category) {
      //   category = filterData.category;
      // }
      for (let i in filterData.subCategory) {
        const subCatIndex = parseInt(i) + 1;
        if (overrideCategoryList.value[subCatIndex]) {
          subCategory.push({
            level: `${subCatIndex}`,
            category: overrideCategoryList.value[subCatIndex].data,
          });
        } else {
          const c = filterData.subCategory[i];
          subCategory.push(c);
        }
      }
      return {
        ...filterData,
        category,
        subCategory,
      };
    };

    const onChartClick = (params) => {
      currentPage.value = 1;

      const { name, color } = params;
      if (level.value < maxLevel.value - 1) {
        overrideCategoryList.value.push({
          data: [name.toLowerCase()],
          color,
        });
        level.value = parseInt(level.value) + 1;
        qs.level = level.value;
        // loading.value = true;
        const newFilterData = prepareOverrideCategoryData();
        chartLoading.value = true;
        tableLoading.value = true;
        getCategory(newFilterData, qs);
        // loadTableData(newFilterData, level.value);
        loadComparisonTable(newFilterData, level.value);
        // loading.value = false;
      }
    };
    const back = () => {
      // const { filterData } = filterResult.value;
      if (level.value > minLevel.value) {
        overrideCategoryList.value.pop();
        level.value = parseInt(level.value) - 1;
        qs.level = level.value;
        // loading.value = true;
        const newFilterData = prepareOverrideCategoryData();
        chartLoading.value = true;
        tableLoading.value = true;
        getCategory(newFilterData, qs);
        // loadTableData(newFilterData, level.value);
        loadComparisonTable(newFilterData, level.value);
        // loading.value = false;
      }
    };
    const mapName = (level) => {
      const bactToLevel = Number(level) - 1;
      return categoryLevelNameList.value[bactToLevel];
    };

    const convertChartValue = (value) => {
      return helper.numeral(value, '0,0.0a', true);
    };

    const convertValue = (value) => {
      if (value) {
        return helper.numeral(value);
      }
      return 0;
    };

    // list pagination
    let currentPage = ref(1);
    const perPage = 10;
    const perPageMobile = 5;

    const paginatedData = computed(() => {
      if (isMobileScreen.value && chartData.value) {
        return chartData.value.slice((currentPage.value - 1) * perPageMobile, currentPage.value * perPageMobile);
      } else if (chartData.value) {
        return chartData.value.slice((currentPage.value - 1) * perPage, currentPage.value * perPage);
      }
      return [];
    });

    const totalPage = computed(() => {
      if (chartData.value && chartData.value.length > 0) {
        return Math.ceil(chartData.value.length / perPage);
      }
      return 0;
    });

    const nextPage = () => {
      if (currentPage.value !== Math.ceil(chartData.value.length / perPage)) {
        currentPage.value += 1;
      }
    };

    const backPage = () => {
      if (currentPage.value !== 1) {
        currentPage.value -= 1;
      }
    };

    // Breadcrumb
    const overrideCategoryListData = computed(() => {
      let newArr = [];
      let children = [];
      if (overrideCategoryList.value) {
        if (overrideCategoryList.value.length > 2) {
          for (let [index, val] of overrideCategoryList.value.entries()) {
            if (index === 0 || index === overrideCategoryList.value.length - 1) {
              newArr.push({
                name: val.data[0],
                data: val.data,
                color: val.color,
                isChild: false,
              });
            } else {
              children.push({
                name: val.data[0],
                data: val.data,
                color: val.color,
                isChild: true,
              });
            }
          }

          // for children
          if (children.length) {
            newArr.splice(1, 0, {
              name: '...',
              data: null,
              color: null,
              isChild: true,
              children: children,
            });
          }
          // return arr;
        } else {
          for (let val of overrideCategoryList.value) {
            newArr.push({
              name: val.data[0],
              data: val.data,
              color: val.color,
              isChild: false,
            });
          }
        }
        return newArr;
      }
      return overrideCategoryList.value;
    });

    const changeStateTable = () => {
      if (!noData.value) {
        showTable.value = !showTable.value;
      }
    };

    return {
      isMobileScreen,
      chartLoading,
      tableLoading,
      showTable,
      level,
      categoryNameList,
      comparisonData,
      // comparisonCategory,
      // comparisonStat,
      getIcon,
      convertMetricName,
      convertValue,
      // loadTableData,
      chartData,
      convertChartValue,
      onChartClick,
      back,
      getColor,
      getColorRgb,
      comparisonEchart,
      onLegendClick,
      minLevel,
      maxLevel,
      mapName,
      overrideCategoryList,
      overrideCategoryListData,
      paginatedData,
      nextPage,
      backPage,
      currentPage,
      totalPage,
      // noData,
      comparisonTableData,
      noData,
      changeStateTable,
    };
  },
};

export default SocialMovement;
</script>

<style lang="scss">
.social-movement-wrapper {
  .ant-card-body {
    // min-height: 955px;
    min-height: 446px;
    // overflow: auto;
  }

  .social-movement-card,
  .breadcrumb-menu {
    // Chart
    .chart-column-card {
      .chart-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .arrow-back {
          display: flex;
          align-self: flex-start;
          align-items: center;
          .back-text {
            margin-left: 5px;
          }

          .level-button {
            width: 120px;
          }
          .level-list {
            display: flex;
            // font-size: 12px;
          }
        }

        x-vue-echarts {
          display: flex !important;
          height: 400px !important;
        }
      }
      // @media only screen and (max-width: 1199px) {
      //   margin-bottom: 30px;
      // }
    }
    .category-list-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .category-list-wrapper {
      min-height: 370px;
      max-height: 400px;
      min-width: 120px;
      max-width: 350px;
      // max-width: 220px;
      overflow: auto;
      padding: 25px 20px 0px 0px;
      // padding: 0px 10px 20px 0px;
      @media only screen and (max-width: 766px) {
        padding: 0px;
        min-width: 330px;
        min-height: 180px;
        align-self: center;
      }
      .category-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &:not(:last-child) {
          margin-bottom: 14px;
        }
        .left-block {
          display: flex;
          align-items: center;
          .category-title {
            .category-name {
              color: #868eae;
            }
          }
        }
        .right-block {
          .right-value {
            color: #868eae;
          }
        }

        .color-muted {
          color: #ccc;
        }
      }
    }

    .paginate-button {
      display: flex;
      justify-content: center;
      min-width: 120px;
      max-width: 350px;
      margin-top: 8px;
      @media only screen and (max-width: 766px) {
        padding: 0px;
        min-width: 330px;
        align-self: center;
      }
    }

    .arrow-back {
      display: flex;
      align-self: flex-start;
      align-items: center;
      .back-text {
        margin-left: 5px;
      }

      .level-button {
        width: 120px;
      }
      .level-list {
        display: flex;
      }
    }

    // Table
    .table-wrapper {
      .comparison-table-scroll {
        //   width: 400px;
        overflow-x: scroll;
        border-left: 1px solid #f1f2f6;
        border-right: 1px solid #f1f2f6;
        //   margin-bottom: 10px;
        //   border: solid black 1px;
        table,
        th,
        td {
          border-collapse: collapse;
          margin: 0;
          white-space: nowrap;
          padding: 16px;
          border-top: 1px solid #f1f2f6;
          // border: 1px solid #f1f2f6;
        }

        table th:first-child {
          position: sticky;
          left: 0;
          background-color: white;
          width: 40px;
        }

        table td:first-child {
          position: sticky;
          left: 0;
          background-color: #f8f9fb;
          width: 40px;
        }

        .comparison-table {
          //   table-layout: fixed;
          width: 100%;

          .column-title {
            text-transform: capitalize;
            padding: 16px;
            z-index: 1;
          }

          .column-title-brand {
            text-align: center;
            text-transform: capitalize;

            .text-brand {
              font-weight: 500;
              color: #272b41;
            }
          }

          .divide-row-title {
            // z-index: 2;
            font-weight: 500;
            line-height: 17px;
            color: #272b41;
            //   border-right: 1px solid transparent;
          }

          .row-title {
            // z-index: 2;
            position: sticky;
            left: 0;
            font-weight: 400;
            line-height: 18px;
            color: #5a5f7d;
            background: #ffffff;
            i {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }

            .metric-name {
              margin-left: 25px;
            }
          }
          .row-title-blank {
            background: #f8f9fb;
            border: none;
          }

          .table-content {
            // color: #5a5f7d;
            text-align: center;
          }
        }
      }
    }

    @media only screen and (max-width: 767px) {
      justify-content: center;
    }
  }

  //  table
  .social-movement-table {
    > .ant-col {
      width: 100%;
    }

    .compairison-table {
      // width: 100%;
      // max-height: 514px;
      // overflow: scroll;
      margin-bottom: 45px;
      border: 1px solid #f1f2f6;
      .ant-table-thead > tr:first-child > th:first-child {
        background-color: #ffffff !important;
        color: #5a5f7d;
        font-weight: 700;
        font-size: 16px;
        // position: sticky;
        // z-index: 1000;
        // left: 0;
        // width: 250px;
      }
      //.ant-table-thead > tr:not(:last-child) > th[colspan]
      .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
        color: #5a5f7d;
        font-weight: 500;
        border: 1px solid #f1f2f6;
        background-color: #f8f9fa;
      }

      .ant-table tbody > tr > td:first-child {
        background: #fff;
        border: 1px solid #f1f2f6;
        // position: sticky;
        // left: 0;
        // z-index: 1000;
        // width: 250px;
      }

      // .ant-table-thead > tr > th[colstart='1'],
      // th[colstart='2'],
      // th[colstart='3'],
      // th[colstart='4'] {
      //   background: rgba(95, 99, 242, 0.08);
      // }

      // .ant-table-thead > tr > th[colstart='5'],
      // th[colstart='6'],
      // th[colstart='7'] {
      //   background: rgba(77, 212, 172, 0.08);
      // }

      .ant-table-thead > tr > th {
        background: rgba(95, 99, 242, 0.08);
      }

      .ant-table-thead > tr > th:nth-child(n + 5) {
        background: rgba(77, 212, 172, 0.08);
      }

      .ant-table tbody > tr > td {
        color: #5a5f7d;
      }

      .ant-table-column-title {
        display: flex;
        align-items: center;
      }

      .metric-name {
        margin-left: 3px;
      }

      .category-text {
        color: #5a5f7d;
        text-transform: capitalize;
      }
    }
  }

  .load-wrapper {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // margin-top: 120px;
    margin: 120px 20px 0px 20px;
    text-align: center;
    // height: 461px;
  }

  .no-data-wrapper {
    display: flex;
    justify-content: center;
    height: 415px;
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}
}
</style>
